import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
/* import VueSocketIO from 'vue-3-socket.io';
import { io } from "socket.io-client"; */
import { vMaska } from "maska";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { PluginOptions, ToastOptionsAndRequiredContent } from 'vue-toastification/dist/types/types';
//import IAccount from './models/interface.account';
import OneSignalVuePlugin from '@onesignal/onesignal-vue3';
import { provideApolloClient } from '@vue/apollo-composable';
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client/core";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { split } from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { asyncMap, getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import IAccount from './models/interface.account';
import { ErrorStatus } from './api/generated/types/graphql';
import useAccount from '@/composition/useAccount';
import { buildAuthHeaders } from './composition/account/helpers';

const { updateToken, logout } = useAccount();

/* import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from "subscriptions-transport-ws"; */

const account: IAccount = JSON.parse(localStorage.getItem('account') as string);

const httpLink = createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_URL,
    headers: {
        Authorization: account ? `Bearer ${account?.accessToken}` : ''
    }
});

const authMiddleware = new ApolloLink((operation, forward) => {
    return asyncMap(forward(operation), async res => {
        if (res.data == null) return res
        const value = Object.values(res.data || { a: null })[0]
        if (!(value instanceof Object) || value === null) return res
        const status: ErrorStatus | null = value['status'] || null
        if (status === null) return res
        if (status !== ErrorStatus.NotAuthenticated) return res
        let newAccessToken: string | null
        try {
            newAccessToken = await updateToken();
        } catch (error) {
            newAccessToken = null
        }
        if (newAccessToken === null) {
            logout()
            return res;
        }
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                ...buildAuthHeaders(newAccessToken as string)
            }
        }));
        return await new Promise(
            resolve => forward(operation).subscribe(resolve)
        )
    })
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: `${process.env.VUE_APP_GRAPHQL_WS_URL}?token=${account?.accessToken}`,
    })
);

/* 
const wsLink = new WebSocketLink(
    new SubscriptionClient(`${process.env.VUE_APP_GRAPHQL_WS_URL}?token=${(account as IAccount).accessToken}`, {
        connectionParams: {
            reconnect: true,
        }
    })
); */

/* const wsLink = new WebSocketLink({
    uri: `${process.env.VUE_APP_GRAPHQL_WS_URL}?token=${(account as IAccount).accessToken}`,
    options: {
        reconnect: true,
    },
}); */

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        )
    },
    wsLink,
    authMiddleware.concat(httpLink)
);

const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});
provideApolloClient(apolloClient);

const filterBeforeCreate = (toast:ToastOptionsAndRequiredContent, toasts:ToastOptionsAndRequiredContent[]) => {
	if (toasts.filter(t => t.content === toast.content).length !== 0) return false;
	return toast;
}
const options: PluginOptions = {
    transition: "Vue-Toastification-Fade",
	containerClassName: "custom-container-class",
    position: POSITION.TOP_CENTER,
	filterBeforeCreate
};

const app = createApp(App).use(router).use(VueAxios, axios).use(Toast, options);
/* if(account) {
    app.use(new VueSocketIO({
        debug: true,
        connection: io('https://api.quickclick.online', {
            path: `${process.env.VUE_APP_API_CHATS_URL}/socket.io`,
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: Infinity,
            transports: ["websocket"],
            withCredentials: true,
            secure: true,
            extraHeaders: {
                Authorization: `Bearer ${(account as IAccount).accessToken}`
            }
        })
    }));
} */

app.directive("maska", vMaska);
app.mount('#app');