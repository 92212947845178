import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { MutateOverrideOptions, MutateResult, useMutation, useQuery } from '@vue/apollo-composable'
import { VariablesParameter } from '@vue/apollo-composable/dist/useQuery'

import useAccount from "@/composition/useAccount";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { reactive, toRefs } from "vue";
import { ErrorStatus } from './generated/types/graphql';

export type ApiResponseList<T> = {
    total?: number,
    items: T
}

export type ApiResponse<T = void> = {
    status: boolean,
    data?: T extends ApiResponseList<T> ? ApiResponseList<T> : T,
    errorMessage?: ErrorStatus,
    fields?: string[]
}

export function useGraphQLQuery<TResult, TVariables extends OperationVariables = OperationVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    variables: VariablesParameter<TVariables>
): Promise<ApolloQueryResult<TResult>> {
    return new Promise((resolve) => {
        const { onResult, onError } = useQuery<TResult, TVariables>(document, variables, {
            fetchPolicy: 'no-cache'
        });
        onResult(result => {
            if(!result.loading && result.data) resolve(result);
        });
        onError(error => {
            if (process.env.NODE_ENV !== 'production') {
                console.log(error);
            }
        });
    })
}

export function useGraphQLMutation<TResult, TVariables extends OperationVariables = OperationVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    variables: VariablesParameter<TVariables>,
    mutationFunc: (variables?: TVariables | null | undefined, overrideOptions?: MutateOverrideOptions<TResult> | undefined) => MutateResult<TResult>
): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const { mutate: mutationFunc, onDone } = useMutation<TResult, TVariables>(document, variables);
        
        onDone(result => {
            if(!(result.data as TResult)) resolve(true);
        });
        reject(false);
    })
}

export enum Status {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface ErrorType {
    error: string;
    message: string;
    statusCode: number;
}

/* type Options = Pick<AxiosRequestConfig, 'url' | 'method' | 'data' | 'params' | 'headers' | "onUploadProgress" | "onDownloadProgress">; */

interface Result<K> {
    status: Status;
    response: null | AxiosResponse<K>;
    error: null | ErrorType;
}

function useApi<T>() {
    const { account, updateToken } = useAccount();
    const ApiClient: AxiosInstance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
            "Content-type": "application/json",
            "Authorization": (account && account.accessToken) ? `Bearer ${account.accessToken}` : ""
        },
    });
    axiosRetry(ApiClient, {
        retries: 1
    });

    const result = reactive<Result<T>>({
        status: Status.IDLE,
        error: null,
        response: null,
    });

    ApiClient.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            console.log("токен устарел: " + account.accessToken);
            originalRequest._retry = true;
            await updateToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${account.accessToken}`;
            //location.href = route.fullPath;
            return ApiClient(originalRequest);
        }
        return Promise.reject(error);
    });
  
    async function call(params: AxiosRequestConfig) {
        result.status = Status.LOADING;
    
        try {
            const requestResponse = await ApiClient.request(params);
    
            result.response = requestResponse;
            result.status = Status.SUCCESS;
        } catch (error) {
            const { response } = error as AxiosError;
            result.response = response as AxiosResponse;
            result.error = response?.data as ErrorType;
            result.status = Status.ERROR;
            console.log((response as { data: { detail: string } }).data.detail);
            switch(response?.status) {
                case 401:
                    //await logout();
                    break;
            }
        }
    }
  
    return { ...toRefs(result), call };
}

export default useApi;