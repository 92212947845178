import { onBeforeMount, reactive, watch } from "vue";
import IApplication, { DEVICE, THEME } from "@/models/interface.application";

const application = reactive<IApplication>({
    isLoading: false,
    userDevice: DEVICE.MOBILE,
    theme: THEME.WHITE
});

export function useApplication() {
    const toggleTheme = (newTheme?: THEME) => {
        application.theme = newTheme ?? application.theme === THEME.WHITE ? THEME.DARK : THEME.WHITE;
    }

    watch(() => application.theme, (newTheme) => {
        localStorage.setItem("theme", newTheme);
        document.body.className = newTheme;
    }, { immediate: true });

    onBeforeMount(() => {
        Object.assign(application, {
            isLoading: false,
            userDevice: DEVICE.MOBILE,
            theme: localStorage.getItem("theme") ? THEME[localStorage.getItem("theme") as keyof typeof THEME] : THEME.WHITE
        });
    });

    return {
        toggleTheme,
        application
    }
}