export enum DEVICE {
    MOBILE = "MOBILE",
    DESKTOP = "DESKTOP"
}

export enum THEME {
    DARK = "DARK",
    WHITE = "WHITE"
}

export default interface IApplication {
    isLoading: boolean;
    userDevice: DEVICE;
    theme: THEME;
}