import { reactive, ref } from "vue";
import {
    ContentAuthorFragment,
    UserFragment,
    Geolocation
} from "@/api/generated/types/graphql";
import { parseWorkingHourse, preparationWorkingHourse } from "./profile/helpers";
import {
    getBlockedUsers,
    getMe,
    listFollowers,
    listSubscriptions,
    getUsers as userGet,
    updateUser as userUpdate,
    blockUser as userBlock,
    unblockUser as userUnblock,
    setFriend,
    deleteFriend,
    setSubscribe,
    unsetSubscribe
} from "@/api/api.users";
import { IUser, IWeekWorkingHours } from "./profile/types";

const isReady = ref<boolean>(false);
const isUnblockPromiseCompleted = ref<boolean>(false);
const blockedList = reactive<{ total: number, items: UserFragment[] }>({
    total: 0,
    items: []
});
const followersList = reactive<{ total: number, items: ContentAuthorFragment[] }>({
    total: 0,
    items: []
});
const subscriptionsList = reactive<{ total: number, items: ContentAuthorFragment[] }>({
    total: 0,
    items: []
});
const users = reactive<UserFragment[]>([]);
const user = reactive<IUser>({
    id: "",
    createdAt: "",
    updatedAt: "",
    login: "",
    avatar: {
        id: "",
        uri: ""
    },
    website: "",
    name: "",
    sex: null,
    dateOfBirth: "",
    email: "",
    phone: "",
    description: "",
    address: {
        city: "",
        coordinates: []
    },
    workingHours: {} as IWeekWorkingHours,
    additionalInfo: {
        hasDelivery: false,
        advantage: "",
    },
    shortDescription: "",
    isBlocked: false,
    viewsCount: 0,
    subscriptionsCount: 0,
    followersCount: 0,
    isFollowing: false,
    isLiked: false,
    likesCount: 0,
    isFriend: false,
    showWorkingHours: false,
    blockedMe: false
});

export function useUsers() {
    const isLoading = ref<boolean>(false);
    //const errors = reactive<string[]>([]);
    const limit = 20
    
    const getUser = async(id?: string) => {
        if(isReady.value) return;
        isLoading.value = true;
        
        try {
            const response = await getMe();
            const dateOfBirth = new Date(response.data?.dateOfBirth as string);
            Object.assign(user, response.data, {
                dateOfBirth: dateOfBirth.getFullYear() + '-' +
                                String(dateOfBirth.getMonth() + 1).padStart(2, '0') + '-' +
                                String(dateOfBirth.getDate()).padStart(2, '0'),
                workingHours: parseWorkingHourse(response.data?.workingHours),
                isHideWorkingHours: response.data?.workingHours ? false : true
            });
            isReady.value = true;
        } catch(error) {
            if (process.env.NODE_ENV !== 'production') throw new Error((error as Error).message);
        }
        
        isLoading.value = false;
    }

    const getUsers = async() => {
        isLoading.value = true;
        const result = await userGet();
        Object.assign(users, result.data);
        isLoading.value = false;
    }

    const updateUser = async(id: string, fields: any) => {
        isLoading.value = true;
        // разбираем расписание
        const workingHours = !fields?.['isHideWorkingHours'].value
            ? preparationWorkingHourse(fields?.['workingHours'].value)
            : null;
    
        //разбираем день рождения
        const fromDate = new Date(fields?.['birthdayDate'].value);
        const today = new Date();
        today.setUTCFullYear(fromDate.getUTCFullYear(), fromDate.getUTCMonth(), fromDate.getUTCDate());
        
        const response = await userUpdate(id, {
            avatarId: fields?.['avatar'].value ? fields?.['avatar'].value.id : null,
            website: fields?.['link'].value,
            name: fields?.['username'].value,
            sex: fields?.['sex'].value,
            dateOfBirth: today.toISOString(),
            description: fields?.['bio'].value,
            shortDescription: fields?.['activityType'].value,
            address: {
                city: fields?.['city'].value,
                coordinates: fields?.['address'].value.map((address: Geolocation) => {
                    return {
                        lat: address.lat,
                        lng: address.lng
                    }
                })
            },
            workingHours: workingHours,
            additionalInfo: {
                hasDelivery: fields?.['hasDelivery'].value,
                advantage: fields?.['advantage'].value,
            }
        });
        isLoading.value = false;

        return response;
    }

    const getListFollowers = async (id: string, q?: string) => {
        const result = await listFollowers(id, q);
        Object.assign(followersList, result.data);
    }

    const getListSubscriptions = async (id: string, q?: string) => {
        const result = await listSubscriptions(id, q);
        Object.assign(subscriptionsList, result.data);
    }

    const getUsersBlocked = async (id: string, query?: string, skip = 0) => {
        const result = await getBlockedUsers(id, query, skip, limit);
        Object.assign(blockedList, result.data);
    }

    const blockUser = async(id: string) => {
        const result = await userBlock(id);
        if(result) {
            blockedList.items.push();
        }
    }

    const unblockUser = async (id: string) => {
        await userUnblock(id);
        const result = blockedList.items.filter((item) => item.id !== id)
        Object.assign(blockedList, {
            ...blockedList,
            items: result
        });
    }

    const friendSet = async (id: string) => {
        await setFriend(id);
    }

    const friendDelete = async (id: string) => {
        await deleteFriend(id);
    }

    const subscribe = async (id: string) => {
        await setSubscribe(id);
    }

    const unsubscribe = async (id: string) => {
        await unsetSubscribe(id);
    }

    return {
        isReady,
        isLoading,
        user,
        users,
        getUser,
        updateUser,
        getUsers,
        getListFollowers,
        followersList,
        getListSubscriptions,
        subscriptionsList,
        getUsersBlocked,
        blockedList,
        blockUser,
        unblockUser,
        isUnblockPromiseCompleted,
        friendSet,
        friendDelete,
        subscribe,
        unsubscribe
    }
}

export default useUsers
