import { reactive, ref } from "vue";
import {
    createToken,
    deleteRefreshToken,
    refreshToken,
    updatePassword as passwordUpdate, 
    loginBySNS} from "@/api/api.account";
import IAccount from "@/models/interface.account";
import { TYPE, useToast } from "vue-toastification";
import useUsers from "./useUsers";
import { ErrorStatus, EsnsType, TokenPair } from "@/api/generated/types/graphql";

const account = reactive<IAccount>({
    isAuthorized: false,
    isAdmin: false,
    accessToken: "",
    refreshToken: ""
});

function useAccount() {
    const toast = useToast();
    const isLoading = ref<boolean>(false);
    const { user } = useUsers();
    
    const auth = async(login?: string, password?: string, tokens?: TokenPair) => {
        clearCache();
        if(!tokens && login && password) {
            const response = await createToken(login, password);
            if(response.status) {
                Object.assign(account, {
                    isAuthorized: true,
                    accessToken: response.data?.accessToken,
                    refreshToken: response.data?.refreshToken
                });
                localStorage.setItem("account", JSON.stringify(account));
            } else {
                switch(response.errorMessage) {
                    case ErrorStatus.InvalidInputData:
                        toast('Не правильный формат: ' + response.fields?.join(', '), { type: TYPE.ERROR });
                        break;
    
                    case ErrorStatus.NotFound:
                        toast('Не найден: ' + response.fields?.join(', '), { type: TYPE.ERROR });
                        break;
                }
            }
        } else if(tokens) {
            Object.assign(account, {
                isAuthorized: true,
                accessToken: tokens.accessToken,
                refreshToken: tokens.refreshToken
            });
            localStorage.setItem("account", JSON.stringify(account));
        }
    }

    const updateToken = async(): Promise<string> => {
        clearCache();
        const result = await refreshToken(account.refreshToken);
        if(result.status && result.data) {
            Object.assign(account, {
                isAuthorized: true,
                isAdmin: false,
                accessToken: result.data.accessToken
            });
            localStorage.setItem("account", JSON.stringify(account));
        }
        return account.accessToken;
    }

    const logout = async() => {
        const result = await deleteRefreshToken(account.refreshToken);
        if(result.status) {
            clearCache();
            return true;
        }
        return false;
    }

    const updatePassword = async(oldPassword: string, newPassword: string) => {
        const result = await passwordUpdate(user.id, oldPassword, newPassword);
        return result.status;
    }

    const loginBySocialsNet = async(accessToken: string, SNSType: EsnsType) => {
        const result = await loginBySNS(accessToken, SNSType);
        if(result.status) {
            await auth(undefined, undefined, result.data);
        } else if(result.errorMessage === ErrorStatus.NotFound) {
            toast("Вы не зарегистрированы в сервисе, пройдите регистрацию через мобильное приложение", { type: TYPE.ERROR });
        } else if(result.errorMessage === ErrorStatus.AlreadyExist) {
            toast("Такой пользователь уже зарегистрирован", { type: TYPE.ERROR });
        }
    }

    const clearCache = () => {
        Object.assign(account, {
            isAuthorized: false,
            isAdmin: false,
            accessToken: ""
        });
        localStorage.removeItem("account");
    }

    if(localStorage.getItem("account")) {
        const localAccount = JSON.parse(localStorage.getItem("account") as string) as IAccount;
        Object.assign(account, { isAuthorized: true }, localAccount);
    }

    return {
        isLoading,
        account,
        auth,
        updateToken,
        logout,
        updatePassword,
        clearCache,
        loginBySocialsNet
    }
}

export default useAccount