import {
    ApiResponse,
    ApiResponseList,
    useGraphQLQuery
} from "@/api/api.client";
import { useMutation } from "@vue/apollo-composable";
import {
    BlockUserDocument,
    BlockUserMutation,
    CodeTransport,
    ContentAuthorFragment,
    DeleteFriendDocument,
    DeleteFriendMutation,
    FollowUserDocument,
    FollowUserMutation,
    GetBlockedUsersDocument,
    GetBlockedUsersQuery,
    GetMeDocument,
    GetMeQuery,
    GetUsersDocument,
    GetUsersQuery,
    IsEmailExistDocument,
    IsEmailExistQuery,
    IsPhoneExistDocument,
    IsPhoneExistQuery,
    ListFollowersDocument,
    ListFollowersQuery,
    ListSubscriptionsDocument,
    ListSubscriptionsQuery,
    RetrieveUserDocument,
    RetrieveUserQuery,
    SetEmailDocument,
    SetEmailMutation,
    SetFriendDocument,
    SetFriendMutation,
    SetPhoneDocument,
    SetPhoneMutation,
    UnblockUserDocument,
    UnblockUserMutation,
    UnfollowUserDocument,
    UnfollowUserMutation,
    UpdateUserDocument,
    UpdateUserMutation,
    UserFragment,
    UserInUpdate
} from "./generated/types/graphql";

export const getMe = async (): Promise<ApiResponse<UserFragment>> => {
    const { data, error } = await useGraphQLQuery<GetMeQuery>(GetMeDocument, {});
    if(data.getMe.__typename === 'User') {
        return {
            status: true,
            data: data.getMe
        }
    }
    return {
        status: false,
        errorMessage: data.getMe.status
    }
}

export const retrieveUser = async (id: string): Promise<UserFragment> => {
    const { data } = await useGraphQLQuery<RetrieveUserQuery>(RetrieveUserDocument, {
        id
    });
    const profile = data.retrieveUser as UserFragment; //тут нужна проверка на typename
    return profile;
}

export const getUsers = async (skip = 0, limit = 20)
    : Promise<ApiResponse<ApiResponseList<ContentAuthorFragment[]>>> => {
    const { data, error } = await useGraphQLQuery<GetUsersQuery>(GetUsersDocument, {
        skip,
        limit
    });
    if(data.getUsers.__typename === 'UserList') {
        return {
            status: true,
            data: {
                total: data.getUsers.total,
                items: data.getUsers.users
            }
        }
    }
    return {
        status: false,
        errorMessage: data.getUsers.status
    }
}

export const updateUser = async (id: string, user: UserInUpdate): Promise<ApiResponse<boolean>> => {
    return new Promise((resolve, reject) => {
        const { mutate: updateUser, onDone } = useMutation<UpdateUserMutation>(UpdateUserDocument);

        updateUser({
            id,
            user
        });
    
        onDone(result => {
            if(!result.data?.updateUser) {
                resolve({ status: true });
            } else {
                reject({
                    status: false,
                    errorMessage: result?.data?.updateUser?.status,
                    fields: result?.data?.updateUser?.fields
                });
            }
        });
    });
}

export const listFollowers = async (id: string, q?: string, skip = 0, limit = 20)
    : Promise<ApiResponse<ApiResponseList<ContentAuthorFragment[]>>> => {
    const { data, error } = await useGraphQLQuery<ListFollowersQuery>(ListFollowersDocument, {
        skip,
        limit,
        id,
        q
    });

    if(data.listFollowers.__typename === 'UserList') {
        return {
            status: true,
            data: {
                total: data.listFollowers.total,
                items: data.listFollowers.users
            }
        }
    }
    return {
        status: false,
        errorMessage: data.listFollowers.status
    }
}

export const listSubscriptions = async (id: string, q?: string, skip = 0, limit = 100)
    : Promise<ApiResponse<ApiResponseList<ContentAuthorFragment[]>>> => {
    const { data, error } = await useGraphQLQuery<ListSubscriptionsQuery>(ListSubscriptionsDocument, {
        skip,
        limit,
        id,
        q
    });

    if(data.listSubscriptions.__typename === 'UserList') {
        return {
            status: true,
            data: {
                total: data.listSubscriptions.total,
                items: data.listSubscriptions.users
            }
        }
    }
    return {
        status: false,
        errorMessage: data.listSubscriptions.status 
    }
}

export const getBlockedUsers = async (id: string, query?: string, skip = 0, limit = 20)
    : Promise<ApiResponse<ApiResponseList<ContentAuthorFragment[]>>> => {
    const { data, error } = await useGraphQLQuery<GetBlockedUsersQuery>(GetBlockedUsersDocument, {
        skip,
        limit,
        id,
        query
    });

    if(data.getBlockedUsers.__typename === 'UserList') {
        return {
            status: true,
            data: {
                total: data.getBlockedUsers.total,
                items: data.getBlockedUsers.users
            }
        }
    }
    return {
        status: false,
        errorMessage: data.getBlockedUsers.status,
        fields: data.getBlockedUsers.fields
    }
}

export const isPhoneExist = async (phone: string)
    : Promise<ApiResponse<boolean>> => {
    const { data, error } = await useGraphQLQuery<IsPhoneExistQuery>(IsPhoneExistDocument, {
        phone
    });

    if(data.isPhoneExist.__typename === 'BooleanObject') {
        return {
            status: true,
            data: data.isPhoneExist.boolean
        }
    }
    return {
        status: false,
        errorMessage: data.isPhoneExist.status
    }
}

export const isEmailExist = async (email: string)
    : Promise<ApiResponse<boolean>> => {
    const { data, error } = await useGraphQLQuery<IsEmailExistQuery>(IsEmailExistDocument, {
        email
    });

    if(data.isEmailExist.__typename === 'BooleanObject') {
        return {
            status: true,
            data: data.isEmailExist.boolean
        }
    }
    return {
        status: false,
        errorMessage: data.isEmailExist.status
    }
}

export const setPhone = async (id: string, transport: string, phone: string)
    : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const { mutate: setPhone, onDone } = useMutation<SetPhoneMutation>(SetPhoneDocument);

        setPhone({
            id,
            transport: CodeTransport[transport as keyof typeof CodeTransport],
            phone
        });

        onDone(result => {
            if(!result.data?.setPhone) resolve(true);
            else reject(false);
        });
    });
}

export const setEmail = async (id: string, transport: string, email: string)
    : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const { mutate: setEmail, onDone } = useMutation<SetEmailMutation>(SetEmailDocument);

        setEmail({
            id,
            transport: CodeTransport[transport as keyof typeof CodeTransport],
            email
        });

        onDone(result => {
            if(!result.data?.setEmail) resolve(true);
            else reject(false);
        });
    });
}

export const blockUser = async (id: string): Promise<boolean> => {
    const { mutate: blockUser, onDone } = useMutation<BlockUserMutation>(BlockUserDocument);

    blockUser({
        id,
    });

    onDone(result => {
        if(!result.data?.blockUser) return true;
    });
    return false;
}

export const unblockUser = async (id: string): Promise<boolean> => {
    const { mutate: unblockUser, onDone } = useMutation<UnblockUserMutation>(UnblockUserDocument);

    unblockUser({
        id,
    });

    onDone(result => {
        if(!result.data?.unblockUser) return true;
    });
    return false;
}

export const setFriend = async (id: string)
    : Promise<boolean> => {
        const { mutate: setFriend, onDone } = useMutation<SetFriendMutation>(SetFriendDocument);

        setFriend({
            id,
        });

        onDone(result => {
            if(!result.data?.setFriend) return true;
        });
        return false;
}

export const deleteFriend = async (id: string)
    : Promise<boolean> => {
        const { mutate: deleteFriend, onDone } = useMutation<DeleteFriendMutation>(DeleteFriendDocument);

        deleteFriend({
            id,
        });

        onDone(result => {
            if(!result.data?.deleteFriend) return true;
        });
        return false;
}

export const setSubscribe = async (id: string): Promise<boolean> => {
    const { mutate: setSubscribe, onDone } =
      useMutation<FollowUserMutation>(FollowUserDocument);

    setSubscribe({
      id,
    });

    onDone((result) => {
      if (!result.data?.followUser) return true;
    });
    return false;
};

export const unsetSubscribe = async (id: string): Promise<boolean> => {
    const { mutate: Unsubscribe, onDone } =
      useMutation<UnfollowUserMutation>(UnfollowUserDocument);

    Unsubscribe({
      id,
    });

    onDone((result) => {
      if (!result.data?.unfollowUser) return true;
    });
    return false;
};