
import { defineComponent, onBeforeUnmount, onMounted, watch } from 'vue';
import { useApplication } from '@/composition/useApplication';
import { DEVICE } from "@/models/interface.application";
import useAccount from '@/composition/useAccount';
import { useRoute, useRouter } from 'vue-router';
import useUsers from '@/composition/useUsers';
import { useOneSignal } from '@onesignal/onesignal-vue3';

export default defineComponent({
    name: "App",
    setup() {
        const { application } = useApplication();
        const router = useRouter();
        const route = useRoute();
        const oneSignal = useOneSignal();

        onMounted(async() => {
            window.addEventListener('resize', setTypeDevice);
            /* await oneSignal.registerForPushNotifications({
                modalPrompt: true,
            }); */
            
            /* oneSignal.promptForPushNotificationsWithUserResponse((response: any) => {
                console.log('Prompt response:', response)
            });
            oneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent: { getNotification: () => any; complete: (arg0: any) => void; }) => {
                console.log(
                'OneSignal: notification will show in foreground:',
                notificationReceivedEvent,
                )
                let notification = notificationReceivedEvent.getNotification()
                console.log('notification: ', notification)
                const data = notification.additionalData
                console.log('additionalData: ', data)
                notificationReceivedEvent.complete(notification)
            });

            oneSignal.setNotificationOpenedHandler((notification: any) => {
                console.log('OneSignal: notification opened:', notification)
            }); */
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', setTypeDevice);
        });

        const { account, auth, logout } = useAccount();
        const { user, getUser } = useUsers();
        /* watchEffect(() => {
            const refAccount = toRefs(account);
            if(refAccount) {
                if(refAccount.isAuthorized) {
                    getProfile();
                } else {
                    router.push({ name: "Login" });
                }
            }
        }); */

        const setTypeDevice = () => {
            if(window.innerWidth <= 920) {
				application.userDevice = DEVICE.MOBILE;
			} else {
				application.userDevice = DEVICE.DESKTOP;
			}
        }

        setTypeDevice();

        return { application, account, auth, logout }
    }
})
