import { DayWorkingHours, WeekWorkingHours, WorkingDayStatus } from "@/api/generated/types/graphql"
import { IWeekWorkingHours } from "./types";

export type WorkingHours = Omit<WeekWorkingHours, '__typename'> & { [index: string]: any }

export const parseWorkingHourse = (
    workingHours: WeekWorkingHours | null | undefined
): IWeekWorkingHours | null => {
    if(!workingHours) return null
    const newWorkingHours = {} as IWeekWorkingHours;
    const entries = Object.entries(workingHours);

    entries.forEach(([key, value]) => {
        if(key === "__typename") return;
        const day = value as DayWorkingHours;
        newWorkingHours[key] = {
            open: day.status === WorkingDayStatus.Working,
            from: day.hours?.opensAt.slice(0, -3) ?? "09:00",
            to: day.hours?.closesAt.slice(0, -3) ?? "18:00"
        }
    });
    
    return newWorkingHours;
}

export const preparationWorkingHourse = (
    workingHours: WorkingHours | null | undefined
): WeekWorkingHours | null => {
    if(!workingHours) return null
    const newWorkingHours = {} as WeekWorkingHours & { [index: string]: DayWorkingHours };
    const entries = Object.entries(workingHours);

    entries.forEach(([key, value]) => {
        newWorkingHours[key] = {
            hours: {
                opensAt: value.from,
                closesAt: value.to
            },
            status: value.open ? WorkingDayStatus.Working : WorkingDayStatus.DayOff
        }
    });
    
    return newWorkingHours;
}