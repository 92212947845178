import { ApiResponse, useGraphQLQuery } from "@/api/api.client";
import {
    AccessToken,
    CodeTransport,
    ConfirmCodeDocument,
    ConfirmCodeMutation,
    CreateTokensDocument,
    CreateTokensQuery,
    CreateUserFromSnsDocument,
    CreateUserFromSnsMutation,
    DeleteRefreshTokenDocument,
    DeleteRefreshTokenQuery,
    EsnsType,
    LoginBySnsDocument,
    LoginBySnsQuery,
    RefreshAccessTokenDocument,
    RefreshAccessTokenQuery,
    SendCodeDocument,
    SendCodeMutation,
    SnsUserIn,
    TokenPair,
    UpdatePasswordDocument,
    UpdatePasswordMutation
} from "./generated/types/graphql";
import { useMutation } from "@vue/apollo-composable";

export const createToken = async (login: string, password: string)
    : Promise<ApiResponse<TokenPair>> => {
    const { data, error } = await useGraphQLQuery<CreateTokensQuery>(
        CreateTokensDocument, {
            login,
            password
        }
    );

    if(data.createTokens.__typename === 'TokenPair') {
        return {
            status: true,
            data: data.createTokens
        }
    }
    return {
        status: false,
        errorMessage: data.createTokens.status,
        fields: data.createTokens.fields
    }
}

export const refreshToken = async (refreshToken: string)
    : Promise<ApiResponse<AccessToken>> => {
    const { data, error } = await useGraphQLQuery<RefreshAccessTokenQuery>(
        RefreshAccessTokenDocument, {
            refreshToken
        }
    );

    if(data.refreshAccessToken.__typename === 'AccessToken') {
        return {
            status: true,
            data: data.refreshAccessToken
        }
    }
    return {
        status: false,
        errorMessage: data.refreshAccessToken.status
    }
}

export const deleteRefreshToken = async (refreshToken: string)
    : Promise<ApiResponse> => {
    const { data, error } = await useGraphQLQuery<DeleteRefreshTokenQuery>(
        DeleteRefreshTokenDocument, {
            refreshToken
        }
    );

    if(!data.deleteRefreshToken) {
        return {
            status: true
        }
    }
    return {
        status: false,
        errorMessage: data.deleteRefreshToken.status
    }
}

export const updatePassword = async (id: string, oldPassword: string, newPassword: string)
    : Promise<ApiResponse> => {
    const { data, error } = await useGraphQLQuery<UpdatePasswordMutation>(
        UpdatePasswordDocument, {
            id,
            password: {
                newPassword,
                oldPassword
            }
        }
    );

    if(!data.updatePassword) {
        return {
            status: true
        }
    }
    return {
        status: false,
        errorMessage: data.updatePassword.status,
        fields: data.updatePassword.fields
    }
}

export const sendCode = async (target: string, transport: string)
    :Promise<ApiResponse> => {
    return new Promise((resolve, reject) => {
        const { mutate: sendCode, onDone } = useMutation<SendCodeMutation>(SendCodeDocument);

        sendCode({
            target,
            transport: CodeTransport[transport as keyof typeof CodeTransport]
        });

        onDone(result => {
            if(!result.data?.sendCode) resolve({
                status: true
            });
            else reject({
                status: false,
                errorMessage: result.data.sendCode.status,
                fields: result.data.sendCode.fields
            });
        });
    });
}

export const confirmCode = async (target: string, transport: string, code: string)
    :Promise<ApiResponse<boolean>> => {
    return new Promise((resolve) => {
        const { mutate: confirmCode, onDone } = useMutation<ConfirmCodeMutation>(ConfirmCodeDocument);

        confirmCode({
            target,
            transport: CodeTransport[transport as keyof typeof CodeTransport],
            code
        });

        onDone(result => {
            if(!result.data?.confirmCode) {
                resolve({ status: true });
            } else {
                resolve({
                    status: false,
                    errorMessage: result.data.confirmCode.status,
                    fields: result.data.confirmCode.fields
                });
            }
        });
    });
}

export const loginBySNS = async (accessToken: string, SNSType: EsnsType)
    : Promise<ApiResponse<TokenPair>> => {
    const { data, error } = await useGraphQLQuery<LoginBySnsQuery>(
        LoginBySnsDocument, {
            accessToken,
            SNSType
        }
    );

    if(data.loginBySNS.__typename === 'TokenPair') {
        return {
            status: true,
            data: data.loginBySNS
        }
    }
    return {
        status: false,
        errorMessage: data.loginBySNS.status,
        fields: data.loginBySNS.fields
    }
}

export const createUserFromSNS = async (user: SnsUserIn)
    : Promise<ApiResponse<string>> => {
    const { data, error } = await useGraphQLQuery<CreateUserFromSnsMutation>(
        CreateUserFromSnsDocument, {
            user
        }
    );

    if(data.createUserFromSNS.__typename === 'User') {
        return {
            status: true,
            data: data.createUserFromSNS.id
        }
    }
    return {
        status: false,
        errorMessage: data.createUserFromSNS.status,
        fields: data.createUserFromSNS.fields
    }
}