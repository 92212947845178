import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import IAccount from "@/models/interface.account";
import { routes } from './routes'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            if (savedPosition) {
                return savedPosition;
            } else {
                resolve({ left: 0, top: 0 });
            }
        });
	}
});

router.beforeEach((to, from, next) => {
    const account: IAccount | null = JSON.parse(localStorage.getItem("account") as string);
    if (!account) {
        if(to.name == "Login" || to.name == "RestorePassword" || to.name == "Landing" || !to.meta.requiresAuth) {
            return next();
        } else {
            return next({name: "Login", query: { referer: to.fullPath }});
        }
    }

    if(account && (to.name == "Login" || to.name == "RestorePassword")) {
        return next({ name: from.name || "Profile" });
    }

    next();
});

export default router
